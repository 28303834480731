<template>
  <b-card>
    <b-row class="mb-2">
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Signature Settings</th>
          </tr>
        </thead>
      </table>
    </b-row>

    <b-alert show variant="warning">
      <div class="alert-body">
        <feather-icon icon="StarIcon" />
        <span class="ml-25">Draw your signature into the box below.</span>
      </div>
    </b-alert>

    <b-row class="m-25">
      <vueSignature ref="signature" :sigOption="option" :disabled="disabled" :w="'500px'" :h="'250px'" class="border border" :defaultUrl="dataUrl"></vueSignature>
      <!-- <vueSignature ref="signature1" :sigOption="option"></vueSignature> -->
    </b-row>
    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-2 mr-1" variant="primary" @click="save">Save</b-button>
    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-2 mr-1" variant="primary" @click="clear">Clear</b-button>
    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-2 mr-1" variant="primary" @click="undo">Undo</b-button>
    <!-- <button @click="addWaterMark">addWaterMark</button> -->
    <!-- <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-2 mr-1" variant="primary" @click="handleDisabled">Disabled</b-button> -->
  </b-card>
</template>

<script>
import vueSignature from 'vue-signature';

import { BAlert, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import router from '@/router';
import store from '@/store';
import axiosIns from '@/libs/axios';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    vueSignature,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    router,
    BAlert,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      option: {
        penColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgb(255,255,255)',
      },
      disabled: false,
      dataUrl: '',

      optionsLocal: {
        name: '',
        surname: '',
        email: '',
        id: '',
        role: '',
        status: '',
        discountLimit: '',
        image: '',
        profile: '',
        signature: '',
      },
    };
  },

  created() {
    var userId = JSON.parse(localStorage.getItem('userData')).id;
    this.optionsLocal.id = userId;
    store
      .dispatch('user/fetchUser', { id: userId })
      .then((response) => {
        this.optionsLocal.signature = response.data.signature;

        if (this.optionsLocal.signature) {
          this.dataUrl = this.fromDataURL(this.optionsLocal.signature);
        }
        //this.imageUrl = this.profileURL + response.data.profile;
      })
      .catch((error) => {
        this.$swal({
          title: 'Error!',
          text: 'Signature could not be updated',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        });
        console.log(error);
      });
  },

  methods: {
    save() {
      var userId = JSON.parse(localStorage.getItem('userData')).id;

      this.$emit('update:formShow', true);
      var _this = this;
      var png = _this.$refs.signature.save();
      //   var jpeg = _this.$refs.signature.save("image/jpeg");
      //   var svg = _this.$refs.signature.save("image/svg+xml");

      this.optionsLocal.signature = png;
      store
        .dispatch('user/updateSignature', _this.optionsLocal)
        .then((response) => {
          if (response.status == 200) {
            _this.$swal({
              title: 'Success!',
              text: 'Signature has been updated!',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              buttonsStyling: false,
            });
          }
          this.$emit('update:formShow', false);
        })
        .catch((error) => {
          this.$emit('update:formShow', false);
          _this.$swal({
            title: 'Error!',
            text: 'Signature could not be updated',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
          console.log(error);
        });
    },

    clear() {
      var _this = this;
      _this.$refs.signature.clear();
    },
    undo() {
      var _this = this;
      _this.$refs.signature.undo();
    },
    // addWaterMark(){
    // 	var _this = this;
    // 	_this.$refs.signature.addWaterMark({
    // 		text:"mark text",          // watermark text, > default ''
    // 		font:"20px Arial",         // mark font, > default '20px sans-serif'
    // 		style:'all',               // fillText and strokeText,  'all'/'stroke'/'fill', > default 'fill
    // 		fillStyle:"red",           // fillcolor, > default '#333'
    // 		strokeStyle:"blue",	   // strokecolor, > default '#333'
    // 		x:100,                     // fill positionX, > default 20
    // 		y:200,                     // fill positionY, > default 20
    // 		sx:100,                    // stroke positionX, > default 40
    // 		sy:200                     // stroke positionY, > default 40
    // 	});
    // },
    fromDataURL(url) {
      var _this = this;
      _this.$refs.signature.fromDataURL(url);
    },

    handleDisabled() {
      var _this = this;
      _this.disabled = !_this.disabled;
    },
  },

  props: {
    formShow: {
      required: false,
    },
  },
};
</script>
